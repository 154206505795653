import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['svg', 'items', 'self', 'menuUpdate'];

  collapsed = true;

  get key() {
    return this.selfTarget.getAttribute('data-menu-key-param')
  }

  get defaultState() {
    return this.selfTarget.getAttribute('data-menu-default-collapsed-state')
  }

  get userState() {
    return window.localStorage.getItem(this.key)
  }

  set userState(payload) {
    window.localStorage.setItem(this.key, payload)
  }

  get initiallyCollapsed() {
    if (!this.userState) {
      return this.defaultState === 'collapsed'
    }

    return this.userState === 'collapsed'
  }

  connect() {
    if (this.initiallyCollapsed) {
      this.collapsed = true
      this.markCollapsed()
    } else {
      this.collapsed = false
      this.markExpanded()
    }

    this.activeItemMenu()
  }

  triggerCollapse() {
    this.collapsed = !this.collapsed
    this.userState = this.collapsed ? 'collapsed' : 'expanded'

    this.updateDom()

    this.customUpdateDom()
  }

  updateDom() {
    if (this.collapsed) {
      this.markCollapsed()
    } else {
      this.markExpanded()
    }
  }

  markCollapsed() {
    this.svgTarget.classList.add('rotate-90')
    this.itemsTarget.classList.add('hidden')
  }

  markExpanded() {
    this.svgTarget.classList.remove('rotate-90')
    this.itemsTarget.classList.remove('hidden')
  }

  toggleMenuSection(menuSection, expand) {
    menuSection.menuUpdateTarget.dataset.menuExpand = expand
    menuSection.svgTarget.classList.toggle('rotate-90', !expand)
    menuSection.itemsTarget.style.display = expand ? 'block' : 'none';
  }

  customUpdateDom() {
    if (this.hasMenuUpdateTarget) {
      const currentSelection = this.menuUpdateTarget

      if (currentSelection.dataset.menuExpand === 'true') {
        this.toggleMenuSection(this, false)
      } else {
        const activeMenuSections = this.findActiveMenuSections()

        activeMenuSections.forEach((activeMenuSection) => {
          this.toggleMenuSection(activeMenuSection, false)
        })
        this.toggleMenuSection(this, true)
      }
    }
  }

  activeItemMenu() {
    const activeMenuSections = this.findActiveMenuSections()

    activeMenuSections.forEach((activeMenuSection) => {

      if (activeMenuSection.hasItemsTarget) {
        const itemsTarget = activeMenuSection.itemsTarget
        if (itemsTarget.querySelector('a.active') !== null) {
          const subMenuItem = itemsTarget.querySelector("[data-menu-target='items'].hidden")
          const subMenuSvg = itemsTarget.querySelector("[data-menu-target='svg']")

          if (subMenuItem) {
            subMenuItem.classList.remove('hidden')
          }
          if (subMenuSvg) {
            subMenuSvg.classList.remove('rotate-90')
          }

          this.toggleMenuSection(activeMenuSection, itemsTarget.querySelector('a.active') !== null)
        } else {
          this.toggleMenuSection(activeMenuSection, false)
        }
      }
    })
  }

  findActiveMenuSections() {
    return this.application.controllers.filter((activeMenuSection) => {
      return activeMenuSection.identifier === 'menu' && activeMenuSection.hasMenuUpdateTarget
    })
  }
}
